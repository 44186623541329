<template>
    <div>
        <div class="hb-primary hb-design-system-border-radius px-4 white--text mb-6">
            <hb-header :divider="false">
                <hb-page-header
                    title="Icons"
                >
                    <template v-slot:description>
                        <div>
                            import { HbIcon } from 'hummingbird-aviary';
                            <div class="mt-2">mdi-codes resource: <hb-link color="secondary" href="https://materialdesignicons.com/" target="_blank">https://materialdesignicons.com/</hb-link></div>
                        </div>
                    </template>
                </hb-page-header>
            </hb-header>
        </div>

        <hb-card title="Hummingbird Material Design Icon Standard Behavior" class="mt-4 mb-6">
            <hb-data-table
                :headers="[
                    { text: 'Type', value: 'type' },
                    { text: 'Icon Only', value: 'icon' },
                    { text: 'Test Button + Icon', value: 'test' },
                    { text: 'Button + Icon Hover', value: 'hover' },
                    { text: 'Button + Icon Active', value: 'active' },
                    { text: 'Button + Icon Disabled', value: 'disabled' },
                ]"
                :items="[
                    { type: 'Default - 24px', icon: 'mdi-magnify', tooltip: 'Search' },
                    { type: 'Small - 16px', small: true, icon: 'mdi-magnify', tooltip: 'Search' },
                ]"
                class="pa-6"
            >
                <template v-slot:item.type="{ item }">
                    {{ item.type }}
                </template>
                <template v-slot:item.icon="{ item }">
                    <hb-icon :small="item.small" mdi-code="mdi-magnify"></hb-icon> 
                </template>
                <template v-slot:item.test="{ item }">
                    <span v-if="item.small">
                        Not Used
                    </span>
                    <span v-else>
                        <hb-btn icon tooltip="Search" mdi-code="mdi-magnify"></hb-btn> 
                    </span>
                </template>
                <template v-slot:item.hover="{ item }">
                    <span v-if="item.small">
                        Not Used
                    </span>
                    <span v-else>
                        <hb-btn icon class="mr-1" tooltip="Search" active active-background-color="#DFE3E8" :mdi-code="item.icon"></hb-btn>
                    </span>
                </template>
                <template v-slot:item.active="{ item }">
                    <span v-if="item.small">
                        Not Used
                    </span>
                    <span v-else>
                        <hb-btn icon class="mr-1" tooltip="Search" active active-background-color="#E0F5F5" :mdi-code="item.icon"></hb-btn>
                    </span>
                </template>
                <template v-slot:item.disabled="{ item }">
                    <span v-if="item.small">
                        Not Used
                    </span>
                    <span v-else>
                        <hb-btn icon tooltip="Search" :disabled="true" mdi-code="mdi-magnify"></hb-btn> 
                    </span> 
                </template>
            </hb-data-table>
        </hb-card>

        <hb-card title="Hummingbird Material Design Button + Icon Master List" class="mt-4 mb-6 pb-1">
            <v-row class="ma-0 pa-0 mx-6 mt-6">
                <v-col cols="6" class="ma-0 pa-0">
                    <v-card class="py-2 px-6 mr-3" color="#E0F5F5">
                        <v-row class="pa-0 ma-0 mb-2 font-weight-medium">Standard hb-button with icon usage: (Optional props are <span class="hb-text-light font-weight-regular mx-1">greyed</span> out)</v-row>
                        <v-row class="pa-0 ma-0 mt-3">&lt;HbBtn</v-row>
                        <v-row class="ma-0 pa-0 pl-12">icon</v-row>
                        <v-row class="ma-0 pa-0 pl-12">mdi-code="mdi-xxxxx"</v-row>
                        <v-row class="ma-0 pa-0 pl-12">tooltip="Xxxxxx"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">active</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">active-state-off</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">active-by-default</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">disabled</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">color="#XXXXXX"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">hover-color="#XXXXXX"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">active-color="#XXXXXX"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">active-background-color="#XXXXXX"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">hover-background-color="#XXXXXX"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">to="xxxxx"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">href="xxxxx"</v-row>
                        <v-row class="ma-0 pa-0 pl-12 hb-text-light">target="xxxxx"</v-row>
                        <v-row class="pa-0 ma-0">/></v-row>
                        
                    </v-card>
                </v-col>
                <v-col cols="6" class="ma-0 pa-0">
                    <v-card class="ml-3 py-2 px-6" color="#E0F5F5">
                        <v-row class="pa-0 ma-0 mb-2 font-weight-medium">Standard hb-icon usage: (Optional props are <span class="hb-text-light font-weight-regular mx-1">greyed</span> out)</v-row>
                        <v-row class="pa-0 ma-0 pl-12">&lt;HbIcon</v-row>
                            <div class="ma-0 pa-0 pl-12">
                                <v-row class="ma-0 pa-0 pl-12">mdi-code="mdi-xxxxx"</v-row>
                                <v-row class="ma-0 pa-0 pl-12 hb-text-light">small</v-row>
                                <v-row class="ma-0 pa-0 pl-12 hb-text-light">color="#XXXXXX"</v-row>
                            </div>
                        <v-row class="pa-0 ma-0 pl-12">/></v-row>
                    </v-card>
                    <v-card class="ml-3 py-10 px-6 mt-6" align="center" color="#E0F5F5">
                        Click "Grab Code" buttons to copy code to clipboard.
                    </v-card>
                    <v-card class="ml-3 pt-9 pb-10 px-6 mt-6" align="center" color="#E0F5F5">
                        Please contact the design team if you cannot find the right "mdi" code for the icon you need.
                    </v-card>
                </v-col>
            </v-row>

            <hb-card :title="list.name" class="ma-6" :class="{ 'mb-8' : i !== lists.length - 1 }" elevation="1" v-for="(list, i) in lists" :key="'list' + i">
                <hb-data-table
                    :headers="masterHeaders"
                    :items="list.items"
                    class="pa-6"
                >
                    <template v-slot:item.button="{ item }">
                        <hb-btn icon :tooltip="item.tooltip" :mdi-code="item.icon"></hb-btn>
                    </template>
                    <template v-slot:item.mdi="{ item }">
                        {{ item.icon }}
                    </template>
                    <template v-slot:item.tooltip="{ item }">
                        {{ item.tooltip }}
                    </template>
                    <template v-slot:item.buttonIconCode="{ item }">
                        <hb-btn :small="true" color="primary" @click="grabButtonIconCode(item)">Grab Button + Icon Code</hb-btn>
                        <div v-if="item.icon === 'mdi-dots-vertical'" :id="'button-icon-code' + item.icon" style="position: absolute;z-index:-999999;top:0;left:0">&lt;HbBtn icon mdi-code="{{ item.icon }}" /></div>
                        <div v-else :id="'button-icon-code' + item.icon" style="position: absolute;z-index:-999999;top:0;left:0">&lt;HbBtn icon tooltip="{{ item.tooltip }}" mdi-code="{{ item.icon }}" /></div>
                    </template>
                    <template v-slot:item.iconCode="{ item }">
                        <hb-btn :small="true" color="primary" @click="grabIconCode(item)">Grab Icon Code</hb-btn>
                        <div :id="'icon-code' + item.icon" style="position: absolute;z-index:-999999;top:0;left:0">&lt;HbIcon mdi-code="{{ item.icon }}" /></div>
                    </template>
                </hb-data-table>
            </hb-card>
        </hb-card>

        <!-- HbIconGroup component below is not a part of design system at the moment -->
        <!--
        <v-card class="mx-0 mt-6 mb-8 pb-4" elevation="1">
            <v-row>
                <v-col cols="7" class="pt-4 pl-9 hb-text-light">
                    Icon Groups
                </v-col>
                <v-col cols="5" class="pt-4 pr-9 text-right">
                </v-col>
            </v-row>
            <hb-icon-group class="mx-6" :icons="icons"></hb-icon-group>
        </v-card>
        -->

        <hb-card title="Profile Avatar Icon Examples (code TBD)" class="mt-4 mb-6">
            <v-row class="py-2 d-flex align-center">
                <v-col>
                    <div class="d-flex justify-center">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="24"
                                cy="24"
                                r="22"
                                fill="white"
                                stroke="#DFE3E8"
                                stroke-width="1.21053"
                            />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24.0001 14.7692C18.9047 14.7692 14.7693 18.9046 14.7693 24C14.7693 29.0954 18.9047 33.2308 24.0001 33.2308C29.0954 33.2308 33.2308 29.0954 33.2308 24C33.2308 18.9046 29.0954 14.7692 24.0001 14.7692ZM23.077 30.4615V28.6154H24.9231V30.4615H23.077ZM26.0031 24.1569L26.8339 23.3077C27.3601 22.7815 27.6924 22.0431 27.6924 21.2308C27.6924 19.1908 26.0401 17.5385 24.0001 17.5385C21.9601 17.5385 20.3077 19.1908 20.3077 21.2308H22.1539C22.1539 20.2154 22.9847 19.3846 24.0001 19.3846C25.0154 19.3846 25.8462 20.2154 25.8462 21.2308C25.8462 21.7385 25.6431 22.2 25.3016 22.5323L24.157 23.6954C23.4924 24.3692 23.077 25.2923 23.077 26.3077V26.7692H24.9231C24.9231 25.3846 25.3385 24.8308 26.0031 24.1569Z" fill="#637381"/>
                        </svg>
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Unknown
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex justify-center">
                        <HbUnitIcon avatar type="storage" />
                    </div>
                    <div class="mt-1 d-flex justify-center">
                    Space
                    </div>
                </v-col>
                <v-col>
                    <div class="d-flex justify-center">
                        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle
                                cx="24"
                                cy="24"
                                r="22"
                                fill="white"
                                stroke="#DFE3E8"
                                stroke-width="1.21053"
                            />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M28 20C28 22.21 26.21 24 24 24C21.79 24 20 22.21 20 20C20 17.79 21.79 16 24 16C26.21 16 28 17.79 28 20ZM16 30C16 27.34 21.33 26 24 26C26.67 26 32 27.34 32 30V32H16V30Z" fill="#637381"/>
                        </svg>
                    </div>
                    <div class="mt-2 d-flex justify-center">
                    Tenant
                    </div>
                </v-col>
                <v-col>
                    <div class="mt-3 d-flex justify-center">
                        <v-avatar color="#101318" size="32" class="white--text hb-user-avatar">
                            A
                        </v-avatar>
                    </div>
                    <div class="mt-3 d-flex justify-center">
                    Profile
                    </div>
                </v-col>
            </v-row>    
        </hb-card>

        <hb-card title="HbBtn Props when 'icon' prop is set to true" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbBtn Slots when 'icon' prop is set to true" class="my-6 pb-1">
            <hb-data-table
                :headers="slotHeaders"
                :items="slotItems"
                class="pa-6"
                >
            </hb-data-table>
        </hb-card>

         <hb-card title="HbIcon Props" class="my-6 pb-1">
            <hb-data-table
                :headers="propHeaders"
                :items="propItems2"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-card title="HbBtn and HbIcon Events" class="my-6 pb-1">
            <hb-data-table
                :headers="eventHeaders"
                :items="eventItems"
                class="pa-6"
            >
            </hb-data-table>
        </hb-card>

        <hb-global-notification
            v-model="success"
            type="success"
            :description="snackbarItem + ' ' + this.type + ' component code copied to clipboard.'"
        >
        </hb-global-notification>

    </div>
</template>
<script type="text/babel">

    export default {
        name: "DesignSystemIcons",
        data: function() {
            return {
                snackbarItem: '',
                type: '',
                success: false,
                icons: [ 'mdi-power', 'mdi-content-save', 'mdi-download', 'mdi-border-style', 'mdi-border-vertical', 'mdi-format-line-spacing' ],
                masterHeaders: [
                    { text: 'Button + Icon', value: 'button' },
                    { text: 'Icon', value: 'mdi' },
                    { text: 'tooltip="Xxxx"', value: 'tooltip' },
                    { text: 'Grab Button + Icon Code', value: 'buttonIconCode' },
                    { text: 'Grab Icon Code', value: 'iconCode' },
                ],
                lists: [
                    { 
                        name: 'Communication',
                        items: [
                            { icon: 'mdi-paperclip', tooltip: 'Attachments' },
                            { icon: 'mdi-coffee', tooltip: 'Break' },
                            { icon: 'mdi-email', tooltip: 'Email' },
                            { icon: 'mdi-email-receive', tooltip: 'Email - Incoming' },
                            { icon: 'mdi-email-send', tooltip: 'Email - Outgoing' },
                            { icon: 'mdi-custom-email-remove', tooltip: 'Email - Failed' },
                            { icon: 'mdi-android-messages', tooltip: 'Messages' },
                            { icon: 'mdi-custom-message-incoming', tooltip: 'Message - Incoming' },
                            { icon: 'mdi-custom-message-outgoing', tooltip: 'Message - Outgoing' },
                            { icon: 'mdi-custom-message-failed', tooltip: 'Message - Failed' },
                            { icon: 'mdi-face-agent', tooltip: 'Live Support' },
                            { icon: 'mdi-content-paste', tooltip: 'Notes' },
                            { icon: 'mdi-custom-pin', tooltip: 'Pinned' },
                            { icon: 'mdi-custom-unpin', tooltip: 'Unpinned' },
                            { icon: 'mdi-forum', tooltip: 'Communication Tool' },
                            { icon: 'mdi-dialpad', tooltip: 'Keypad' },
                            { icon: 'mdi-headset', tooltip: 'Headset' },
                            { icon: 'mdi-workflows-close-of-day', tooltip: 'Close of Day' },
                            { icon: 'mdi-custom-hummingbird', tooltip: 'Hummingbird'}
                        ]
                    },
                    { 
                        name: 'Financial',
                        items: [
                            { icon: 'mdi-credit-card-wireless', tooltip: 'AutoPay Enrollment' },
                            { icon: 'mdi-sale', tooltip: 'Discount' },
                            { icon: 'mdi-credit-card-outline', tooltip: 'Payment Methods' },
                            { icon: 'mdi-credit-card-off-outline', tooltip: 'Void Issued' },
                            { icon: 'mdi-custom-collection-calls', tooltip: 'Collection Calls' },
                            { icon: 'mdi-custom-invoice', tooltip: 'Invoice' },
                            { icon: 'mdi-bullhorn', tooltip: 'Promotions' },
                            { icon: 'mdi-cart', tooltip: 'Cart' },
                            { icon: 'mdi-cart-off', tooltip: 'Cart Off' },
                            { icon: 'mdi-credit-card-minus', tooltip: 'Late Fee Charged' },
                            { icon: 'mdi-percent', tooltip: 'Percent' },
                            { icon: 'mdi-custom-transaction-history', tooltip: 'Transaction History' },
                            { icon: 'mdi-cash-refund', tooltip: 'Refund' },
                            { icon: 'mdi-credit-card-off-outline', tooltip: 'Credit Card Declined' },
                            { icon: 'mdi-tag', tooltip: 'Merchandise' },
                            { icon: 'mdi-receipt', tooltip: 'Receipt' },
                            { icon: 'mdi-checkbook', tooltip: 'Checkbook' },
                            { icon: 'mdi-cash-multiple', tooltip: 'Multiple Cash' },
                            { icon: 'mdi-credit-card-refund-outline', tooltip: 'Tenant Chargeback' },
                            { icon: 'mdi-cash-plus', tooltip: 'Credit Issued' },
                            { icon: 'mdi-custom-financial-cash-sync', tooltip: 'Payment Processing' },
                            { icon: 'mdi-currency-usd', tooltip: 'Payment' },
                            { icon: 'mdi-credit-card-refund', tooltip: 'Tenant Refund' },
                            { icon: 'mdi-coin-outline', tooltip: 'Transaction History' },
                            { icon: 'mdi-custom-credit-card-wireless-off', tooltip: 'AutoPay Enrollment off' },
                            { icon: 'mdi-custom-veritec', tooltip: 'Veritec'},
                            { icon: 'mdi-custom-prorize', tooltip: 'Prorize'}
                        ]
                    },
                    { 
                        name: 'Navigation',
                        items: [
                            { icon: 'mdi-custom-administrators', tooltip: 'Administrators' },
                            { icon: 'mdi-apps', tooltip: 'Applications' },
                            { icon: 'mdi-camera-control', tooltip: 'Channels' },
                            { icon: 'mdi-view-dashboard', tooltip: 'Dashboard' },
                            { icon: 'mdi-home', tooltip: 'Home' },
                            { icon: 'mdi-basket', tooltip: 'Marketplace' },
                            { icon: 'mdi-system-navigation-custom-1', tooltip: 'Move-Out' },
                            { icon: 'mdi-settings', tooltip: 'Settings' },
                            { icon: 'mdi-account-box', tooltip: 'Tenant Onboarding' },
                            { icon: 'mdi-flash-circle', tooltip: 'Quick Actions' },
                            { icon: 'recent_actors', tooltip: 'Leads' },
                            { icon: 'mdi-tools', tooltip: 'Tools' },
                            { icon: 'mdi-logout', tooltip: 'Log Out' },
                            { icon: 'mdi-cloud-search', tooltip: 'Global Search' },
                            { icon: 'mdi-custom-main-menu', tooltip: 'Hide Navigation' },
                            { icon: 'mdi-custom-main-menu-arrow', tooltip: 'Show Navigation' },
                            { icon: 'mdi-table-actions-custom-1', tooltip: 'Sidebar' },
                            { icon: 'mdi-file-chart', tooltip: 'Reports' },
                            { icon: 'mdi-custom-rent-management', tooltip: 'Rent Management' },
                            { icon: 'mdi-chevron-down', tooltip: 'Down' },
                            { icon: 'mdi-chevron-right', tooltip: 'Right' },
                            { icon: 'mdi-chevron-up', tooltip: 'Up' },
                            { icon: 'mdi-chevron-left', tooltip: 'Left' },
                            { icon: 'mdi-menu-up', tooltip: 'Up' },
                            { icon: 'mdi-menu-down', tooltip: 'Down' },
                        ]
                    },
                    { 
                        name: 'Phone / SMS',
                        items: [
                            { icon: 'mdi-phone-in-talk', tooltip: 'Ongoing call' },
                            { icon: 'mdi-phone-cancel', tooltip: 'Call Cancelled' },
                            { icon: 'mdi-phone-forward', tooltip: 'Forward Call' },
                            { icon: 'mdi-phone-outgoing', tooltip: 'Calling' },
                            { icon: 'mdi-phone-alert', tooltip: 'Call Alert' },
                            { icon: 'mdi-phone-incoming', tooltip: 'Incoming Call' },
                            { icon: 'mdi-phone-missed', tooltip: 'Missed Call' },
                            { icon: 'mdi-phone-lock', tooltip: 'Locked Call' },
                            { icon: 'mdi-phone-minus', tooltip: 'Minus Call' },
                            { icon: 'mdi-phone-return', tooltip: 'Return Call' },
                            { icon: 'mdi-phone-paused', tooltip: 'Hold Call' },
                            { icon: 'mdi-phone-hangup', tooltip: 'Hang Up' },
                            { icon: 'mdi-voicemail', tooltip: 'Voicemail' },
                            { icon: 'mdi-phone-check', tooltip: 'Call Completed' },
                            { icon: 'mdi-message-processing', tooltip: 'SMS / Responding' },
                            { icon: 'mdi-message-reply-text', tooltip: 'SMS / Text Reply' },
                            { icon: 'mdi-message-text-clock', tooltip: 'SMS / Text Clock' },
                            { icon: 'mdi-message-text-lock', tooltip: 'SMS / Text Lock' },
                            { icon: 'mdi-phone', tooltip: 'Phone' },
                        ]
                    },
                    { 
                        name: 'Profile',
                        items: [
                            { icon: 'mdi-custom-active-military', tooltip: 'Active Military' },
                            { icon: 'mdi-custom-bankrupt', tooltip: 'Bankrupt' },
                            { icon: 'mdi-users-custom-1', tooltip: 'Lease Summary' },
                            { icon: 'mdi-facility-custom-2', tooltip: 'Property' },
                            { icon: 'mdi-facility-custom-3', tooltip: 'Space' },
                            { icon: 'mdi-subtitles', tooltip: 'Apps' },
                            { icon: 'mdi-account', tooltip: 'Person' },
                            { icon: 'mdi-account-check', tooltip: 'Verified User' },
                            { icon: 'mdi-contacts', tooltip: 'Contact Details' },
                            { icon: 'mdi-account-supervisor-circle', tooltip: 'User Management' },
                            { icon: 'mdi-help-circle', tooltip: 'Help' },
                            { icon: 'mdi-account-cancel', tooltip: 'Blacklisted' },
                            { icon: 'mdi-toolbox', tooltip: 'Maintenance Requested' },
                            { icon: 'mdi-card-bulleted-off', tooltip: 'Registration Expired' },
                            { icon: 'mdi-draw', tooltip: 'Signature Needed' },
                            { icon: 'mdi-key-variant', tooltip: 'Authorize to Hold Keys' },
                            { icon: 'mdi-boom-gate-up', tooltip: 'Gate Code Issued' },
                            { icon: 'mdi-boom-gate-alert', tooltip: 'Gate Access Suspended' },
                            { icon: 'mdi-sticker-alert-outline', tooltip: 'Lien Notice' },
                            { icon: 'mdi-custom-text-box-plus', tooltip: 'Rental Lease' },
                            { icon: 'mdi-shield-edit', tooltip: 'Insurance Enrollment' },
                            { icon: 'mdi-custom-shield-sync', tooltip: 'Insurance Change' },
                            { icon: 'mdi-shield-off', tooltip: 'Insurance Expired' },
                            { icon: 'mdi-sync', tooltip: 'Change Access Code' },
                            { icon: 'mdi-car-back', tooltip: 'Vehicle Registration Expiration' },
                            { icon: 'mdi-briefcase-check-outline', tooltip: 'Move-Out Confirmation' },
                            { icon: 'mdi-tag', tooltip: 'Services' },
                            { icon: 'mdi-check-decagram', tooltip: 'Pro Tip' },
                            { icon: 'mdi-domain', tooltip: 'Company' },
                        ]
                    },
                    { 
                        name: 'System Actions',
                        items: [
                            { icon: 'mdi-alert-circle-outline', tooltip: 'Caution' },
                            { icon: 'mdi-hand-left', tooltip: 'Manual' },
                            { icon: 'mdi-state-machine', tooltip: 'Automated' },
                            { icon: 'mdi-close-circle', tooltip: 'Clear' },
                            { icon: 'mdi-plus', tooltip: 'Add' },
                            { icon: 'mdi-refresh', tooltip: 'Refresh' },
                            { icon: 'mdi-alert', tooltip: 'Warning' },
                            { icon: 'mdi-content-copy', tooltip: 'Duplicate' },
                            { icon: 'mdi-delete', tooltip: 'Delete' },
                            { icon: 'mdi-close-circle-outline', tooltip: 'Cancel' },
                            { icon: 'mdi-debug-step-over', tooltip: 'Skip' },
                            { icon: 'mdi-check-circle', tooltip: 'Complete' },
                            { icon: 'mdi-magnify-minus-outline', tooltip: 'Zoom Out' },
                            { icon: 'mdi-magnify-plus-outline', tooltip: 'Zoom In' },
                            { icon: 'mdi-printer', tooltip: 'Print' },
                            { icon: 'mdi-open-in-app', tooltip: 'Launch' },
                        ]
                    },
                    { 
                        name: 'Table Actions',
                        items: [
                            { icon: 'mdi-border-style', tooltip: 'Advanced Mode' },
                            { icon: 'mdi-close', tooltip: 'Close' },
                            { icon: 'mdi-pencil', tooltip: 'Edit' },
                            { icon: 'mdi-arrow-expand-right', tooltip: 'Expand Menu' },
                            { icon: 'mdi-filter-variant', tooltip: 'Filter' },
                            { icon: 'mdi-format-line-spacing', tooltip: 'Format Line Spacing' },
                            { icon: 'mdi-power', tooltip: 'Log-In' },
                            { icon: 'mdi-power', tooltip: 'Log-Out' },
                            { icon: 'mdi-sort-variant', tooltip: 'Sort' },
                            { icon: 'mdi-table-actions-custom-2', tooltip: 'Columns' },
                            { icon: 'mdi-table-actions-custom-3', tooltip: 'Advanced' },
                            { icon: 'mdi-table-actions-enable', tooltip: 'Enabled' },
                            { icon: 'mdi-table-actions-disable', tooltip: 'Disabled' },
                            { icon: 'mdi-custom-add-spaces', tooltip: 'Add Spaces' },
                        ]
                    },
                    { 
                        name: 'User Actions',
                        items: [
                            { icon: 'mdi-user-actions-custom-1', tooltip: 'Share' },
                            { icon: 'mdi-note-plus', tooltip: 'Add Note' },
                            { icon: 'mdi-calendar-blank', tooltip: 'Calendar' },
                            { icon: 'mdi-account-plus', tooltip: 'Add User' },
                            { icon: 'mdi-block-helper', tooltip: 'Block' },
                            { icon: 'mdi-check', tooltip: 'Check' },
                            { icon: 'mdi-delete', tooltip: 'Delete' },
                            { icon: 'mdi-download', tooltip: 'Download' },
                            { icon: 'mdi-cloud-download', tooltip: 'Download Data' },
                            { icon: 'mdi-fullscreen', tooltip: 'Fullscreen' },
                            { icon: 'mdi-email-send', tooltip: 'Mark Spam' },
                            { icon: 'mdi-dots-vertical', tooltip: '' },
                            { icon: 'mdi-account-minus', tooltip: 'Remove User' },
                            { icon: 'mdi-content-save', tooltip: 'Save' },
                            { icon: 'mdi-magnify', tooltip: 'Search' },
                            { icon: 'mdi-view-module', tooltip: 'Tile' },
                            { icon: 'mdi-eye', tooltip: 'View' },
                            { icon: 'mdi-eye', tooltip: 'Hide' },
                            { icon: 'mdi-file-search', tooltip: 'Browse' },
                            { icon: 'mdi-gesture-tap', tooltip: 'Triggers' },
                            { icon: 'mdi-clock-outline', tooltip: 'Clock' },
                            { icon: 'mdi-record-circle', tooltip: 'Change of Status' },
                            { icon: 'mdi-image-broken', tooltip: 'Damage Report' },
                            { icon: 'mdi-account-cancel', tooltip: 'Blacklisted' },
                            { icon: 'mdi-workflows-transfer', tooltip: 'Transfer' },
                            { icon: 'mdi-help-circle-outline', tooltip: 'Help' },
                            { icon: 'mdi-information', tooltip: 'Info' },
                            { icon: 'mdi-close-circle', tooltip: 'Cancel' },
                            { icon: 'mdi-drag-custom', tooltip: 'Drag' },
                            { icon: 'mdi-workflows-close-of-day', tooltip: 'Close of Day' },
                            { icon: 'mdi-link-variant', tooltip: 'Link Contacts' },
                        ]
                    },
                    { 
                        name: 'Tasks',
                        items: [
                            { icon: 'mdi-access-point', tooltip: 'Access' },
                            { icon: 'mdi-broom', tooltip: 'Cleaning' },
                            { icon: 'mdi-file', tooltip: 'Documents' },
                            { icon: 'mdi-gavel', tooltip: 'Gavel' },
                            { icon: 'mdi-calendar-clock', tooltip: 'Reservations' },
                            { icon: 'mdi-lock', tooltip: 'Locked' },
                            { icon: 'mdi-lock-open-outline', tooltip: 'Remove Overlock' },
                            { icon: 'mdi-check-circle-outline', tooltip: 'Completed Tasks' },
                            { icon: 'mdi-custom-lock-open-alert', tooltip: 'Lock Cut Inventory' },
                            { icon: 'mdi-bell', tooltip: 'Notifications' },
                            { icon: 'mdi-playlist-check', tooltip: 'Task Center' },
                        ]
                    },
                    { 
                        name: 'Miscellaneous',
                        items: [
                            { icon: 'mdi-home', tooltip: 'Home' },
                            { icon: 'mdi-account', tooltip: 'Account' },
                            { icon: 'mdi-facility-custom-3', tooltip: 'Space' },
                            { icon: 'mdi-rv-truck', tooltip: 'Parking' },
                            { icon: 'mdi-rv-truck', tooltip: 'Vehicle' },
                            { icon: 'mdi-rv-truck', tooltip: 'Truck' },
                            { icon: 'mdi-domain', tooltip: 'Residential' },
                            { icon: 'mdi-domain', tooltip: 'Company' },
                            { icon: 'mdi-domain', tooltip: 'Domain' },
                        ]
                    },
                    { 
                        name: 'Workflows',
                        items: [
                            { icon: 'mdi-workflows-rent-management', tooltip: 'Rent Management' },
                            { icon: 'mdi-workflows-transfer', tooltip: 'Transfer' },
                            { icon: 'mdi-workflows-close-of-day', tooltip: 'Close of Day' },
                        ]
                    },
                    { 
                        name: 'Property Map',
                        items: [
                            { icon: 'mdi-custom-door-open-left', tooltip: 'Door Open Left' },
                            { icon: 'mdi-custom-door-open-right', tooltip: 'Door Open Right' },
                            { icon: 'mdi-custom-boom-gate', tooltip: 'Boom Gate' },
                            { icon: 'mdi-gate', tooltip: 'Iron Gate' },
                            { icon: 'mdi-elevator-passenger', tooltip: 'Elevator' },
                            { icon: 'mdi-alarm-light', tooltip: 'Alarm' },
                            { icon: 'mdi-car', tooltip: 'Drive Up' },
                            { icon: 'mdi-custom-conference-room', tooltip: 'Conference Room' },
                            { icon: 'mdi-parking', tooltip: 'Parking' },
                            { icon: 'mdi-human-male-female', tooltip: 'Restroom' },
                            { icon: 'mdi-dolly', tooltip: 'Loading Dock' },
                            { icon: 'mdi-fire-extinguisher', tooltip: 'Extinguisher' },
                            { icon: 'mdi-bottle-soda-classic', tooltip: 'Vending Machine' },
                            { icon: 'mdi-fire-hydrant', tooltip: 'Fire Hydrant' },
                            { icon: 'mdi-custom-flood-light', tooltip: 'Flood Light' },
                            { icon: 'mdi-custom-lamp-post', tooltip: 'Lamp Post' },
                            { icon: 'mdi-flag', tooltip: 'Flag Pole' }
                        ]
                    },
                    { 
                        name: 'Reports',
                        items: [
                            { icon: 'mdi-custom-reports-application', tooltip: 'Application Report' },
                        ]
                    },
                ],
                propHeaders: [
                    { text: 'Name', value: 'name', width: '200' },
                    { text: 'Type', value: 'type', width: '110' },
                    { text: 'Default', value: 'default', width: '110' },
                    { text: 'Description', value: 'description' },
                ],
                propItems: [
                    { name: 'icon', type: 'boolean', default: 'false', description: 'You must set this to true for when you need an icon to behave like a round icon button, otherwise it will not work. This adds all the necessary stylings and behaviors. Must set a valid mdi-code in the HbBtn "mdi-code" prop for this to work.' },
                    { name: 'mdi-code', type: 'string', default: 'undefined', description: 'Set the icon mdi-code here. Must be a valid mdi-code from https://materialdesignicons.com/ or one of the custom hummingbird codes.' },
                    { name: 'tooltip', type: 'string', default: 'undefined', description: 'Sets the tooltip text for when the user hovers over the button-icon. If you need to set a tooltip header as well, use the "tooltipHeader" v-slot method instead.' },
                    { name: 'disable-tooltip', type: 'boolean', default: 'false', description: 'If set to true, turns off the tooltip.' },
                    { name: 'active', type: 'boolean', default: 'false', description: 'Manually control the active state and stylings.' },
                    { name: 'active-state-off', type: 'boolean', default: 'false', description: 'If set to true, turns off the active state stylings when button is clicked/active.' },
                    { name: 'active-by-default', type: 'boolean', default: 'false', description: 'If set to true, turns the button-icon to active by default and applies the active stylings.' },
                    { name: 'disabled', type: 'boolean', default: 'false', description: 'If set to true, this disables the button-icon.' },
                    { name: 'color', type: 'string', default: '#637381', description: 'Manually set the color of the icon according to hex code or standard css type color text word. You can also use color="primary", "caution", "error" or "success" for standard aviary approved primary and alternate colors.' },
                    { name: 'hover-color', type: 'string', default: 'undefined', description: 'Manually set the hover color of the icon.' },
                    { name: 'active-color', type: 'string', default: 'undefined', description: 'Manually set the active color of the icon.' },
                    { name: 'active-background-color', type: 'string', default: 'undefined', description: 'Manually set the active background color of the icon.' },
                    { name: 'hover-background-color', type: 'string', default: 'undefined', description: 'Manually set the hover background color of the icon.' },
                    { name: 'to', type: 'string', default: 'undefined', description: 'Sets the vue route to go to when the button-icon is clicked. For internal links.' },
                    { name: 'href', type: 'string', default: 'undefined', description: 'Sets the href value for the button-icon. Usually for external links.' },
                    { name: 'target', type: 'string', default: 'undefined', description: 'Sets the "target" attribute value when "href" is also set. For example, set "target=_blank" to open in a new tab when clicked.' },
                    { name: 'small-size-position-fix', type: 'boolean', default: 'false', description: 'If set to true and "small" prop is used, applies custom adjustments to make the icon look nicely aligned to nearby elements. If the icon is not looking nicely aligned, try setting this prop to true to see if that fixes it.' },
                ],
                propItems2: [
                    { name: 'mdi-code', type: 'string', default: 'undefined', description: 'Place the chosen material design icon "mdi-code-xxx" here.' },
                    { name: 'small', type: 'boolean', default: 'false', description: 'If set to true, the icon size will be smaller.' },
                    { name: 'color', type: 'string', default: 'undefined', description: 'Manually set the color of the icon.' },
                    { name: 'pointer', type: 'boolean', default: 'false', description: 'If set to true, "cursor: pointer;" css style will be added to the icon when hovered over.' },
                ],
                slotHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                slotItems: [
                    { name: 'tooltipHeader', description: 'This slot is where you can set the tooltip header content.' },
                    { name: 'tooltipBody', description: 'This slot is where you can set the tooltip body content.' },
                ],
                eventHeaders: [
                    { text: 'Name', value: 'name' },
                    { text: 'Description', value: 'description' },
                ],
                eventItems: [
                    { name: '@click', description: 'Event for when item is clicked. HbBtn and HbIcon both have this event.' },
                ]
            };
        },
        methods: {
            grabButtonIconCode(item){
                this.snackbarItem = item.icon;
                window.getSelection().empty();
                var range = document.createRange();
                range.selectNode(document.getElementById("button-icon-code" + item.icon));
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.type = "<hb-btn>";
                this.success = true;
            },
            grabIconCode(item){
                this.snackbarItem = item.icon;
                window.getSelection().empty();
                var range = document.createRange();
                range.selectNode(document.getElementById("icon-code" + item.icon));
                window.getSelection().addRange(range);
                document.execCommand("copy");
                this.type = "<hb-icon>";
                this.success = true;
            }
        }
    }
</script>

<style scoped>

</style>
